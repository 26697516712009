import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';


export default class HideIfClickedOutside extends React.Component  {
	constructor(props) {
		super(props);

		this.state = {
			visible: this.props.visible || false
		}

		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.setWrapperRef = this.setWrapperRef.bind(this);
	}

	setWrapperRef(node) {
	    this.wrapperRef = node;
	}

	componentWillReceiveProps(nextProps) {
	 	this.setState(update(this.state, {
	 		visible: {$set: nextProps.visible}
	 	}))
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	handleClickOutside(event) {
		if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			this.setState(update(this.state, {
				visible: {$set: false}
			}))
		 	this.props.callback();
		}
	}

	render() {
		return this.state.visible
			? <span className={this.props.className} ref={this.setWrapperRef}>{this.props.children}</span>
			: <span className="hiddenContent" />;
	}
}

HideIfClickedOutside.propTypes = {
	visible: PropTypes.bool.isRequired,
	callback: PropTypes.func.isRequired,
	className: PropTypes.string.isRequired,
	children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
}