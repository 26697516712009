import React from 'react';
import { PrimaryButton } from '../components/shared/Buttons';

import styles from './../assets/scss/fourOhFour.module.scss';

const FourOhFour = () => (

  <div className={styles.page}>
    <div className={styles.content}>
    <h1>Error: 404</h1>
    <p>Page does not exist!</p>
    <PrimaryButton to="/">Return Home</PrimaryButton>
    </div>
  </div>
)

export default FourOhFour;