import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../assets/scss/modal.module.scss';
import HideIfClickedOutside from './HideIfClickedOutside';
import { generateClassList } from '../helpers';

const Modal = ({ handleClose, show, children, className, closeBtnClass , childClass, showClose }) => {
  const showHideClassName = show ? styles.displayBlock : styles.displayNone;
  return (
    <div className={generateClassList(styles.modal, showHideClassName, className)}>
      <section className={styles.main}>
      	<HideIfClickedOutside visible={show} className="" callback={handleClose}>
        <div className={generateClassList(styles.childContainer, childClass)}>{children}</div>
         </HideIfClickedOutside>
         { (showClose)
          ? <span className={generateClassList(styles.closeBtn, closeBtnClass)} onClick={handleClose}>X</span>
          : <span />
        }

      </section>
    </div>
  );
};

Modal.defaultProps = {
  className: '',
  closeBtnClass:  '',
  childClass: '',
  showClose: true
}
Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]).isRequired,
  className: PropTypes.string,
  closeBtnClass: PropTypes.string,
  childClass: PropTypes.string,
  showClose: PropTypes.bool,
}
export default Modal;
