import React from 'react';
import PropTypes from 'prop-types';
import headerLogo from './../../assets/logo.png';
import {ImageLink} from './../shared/PageContent';
import HideIfClickedOutside from './../shared/HideIfClickedOutside';
import {NavLinks} from './../../navLinks.js';
import update from 'immutability-helper';
import { generateClassList } from '../helpers';
import AudioPlayer from '../shared/AudioPlayer';
import {NavLink} from 'react-router-dom';
import styles from '../../assets/scss/header.module.scss';


export class NavListItem extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      visible: (props.children) ? false : true,
      ...props
    };
  }

  expandSubMenu = () => {
    // e.preventDefault();
    const { visible } = this.state;
    this.setState(update(this.state, {
      visible: {$set: ! visible}
    }))
  }

  renderTitleLink = () => {
    let { children : hasChildren, visible, title, path, exact } = this.state;
    const { clickAction } = this.props;

    const linkProps = {
      onClick: clickAction, // parent toggleNav function - ensures nav will close when navigating to a new page
      exact: exact,
      to: path,
      activeClassName: styles.active
    };

    if (! hasChildren ) {
      return <NavLink {...linkProps}>{title}</NavLink>
    } else {

      // return (!visible) // uncomment to enable toggling of nav items......
      return (visible)
        ? <span className={styles.expandable} onClick={ this.expandSubMenu }>{title}</span>
        : <span className={styles.expanded}><NavLink {...linkProps}>{title}</NavLink><NavBlock child nav={hasChildren} clickAction={clickAction} /></span>
    }
  }

  render() {
    const { children, visible } = this.props;

    return (
      <li className={styles.navListItem}>
        { this.renderTitleLink() }
        {
          (children && visible)
            ? <NavBlock nav={children}/>
            : <span />
        }
      </li>
    )
  }
}

NavListItem.defaultProps = {
  exact: true,
}

NavListItem.propTypes = {
  clickAction: PropTypes.func.isRequired,
  component: PropTypes.func.isRequired,
  children: PropTypes.arrayOf(PropTypes.object),
  exact: PropTypes.bool,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export const NavBlock = ({ nav : navData, child, clickAction }) => {
  const nav = navData.map(item =>  <NavListItem clickAction={clickAction} key={item.path} {...item} />);
  return (
    <ul className={(child) ? styles.childMenu : null }>{nav}</ul>
  )
}

NavBlock.defaultProps = {
  child: false,
  active: false,
  clickAction: () => {}
}

NavBlock.propTypes = {
  nav: PropTypes.arrayOf(PropTypes.object).isRequired,
  child: PropTypes.bool,
  clickAction: PropTypes.func,
  active: PropTypes.bool,
}


export const NavIcon = ({ onClick, className }) => (

  <span className={generateClassList(styles.navIcon, className)} onClick={onClick}>
    <span className={styles.dot}></span>
    <span className={styles.dot}></span>
    <span className={styles.dot}></span>
  </span>
)

NavIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
}

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showNav: props.showNav
    }
  }

  hideNav = () => {
     this.setState(update(this.state, {
      showNav: {$set: false}
    }))
  }
  toggleNav = () => {
    const {showNav: showNavCurrent} = this.state;
    this.setState(update(this.state, {
      showNav: {$set: !showNavCurrent}
    }))
  }

  render() {
    const { showNav } = this.state;

    const wrapperClass = `${styles.navWrapper} ${(showNav) ? styles.active : ''}`;
    const logoClass = (showNav) ? styles.hideLogo : '';

    return (
      <div className={styles.header}>
        <ImageLink to="/" src={headerLogo} className={generateClassList(styles.logo, logoClass)} />

        <div className={wrapperClass}>
            <AudioPlayer />

          { (showNav)
            ? (<HideIfClickedOutside visible={showNav} callback={this.toggleNav} className={''}>
                <NavIcon onClick={ this.toggleNav}  />
                <span  className={styles.navContainer}>
                <NavBlock nav={NavLinks} clickAction={this.hideNav} active />
                </span>
              </HideIfClickedOutside>)
            : (
              <span>
                <NavIcon onClick={ this.toggleNav}  />
                <span className="hiddenNav" />
              </span>
            )
          }
        </div>

      </div>
    )

  }
}

Header.defaultProps = {
  showNav: false
}

Header.propTypes = {
  showNav: PropTypes.bool
}
export default Header;
