import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { generateClassList } from '../helpers';
import styles from '../../assets/scss/buttons.module.scss';

export const Button = (props) => <Link {...props}>{props.children}</Link>

Button.propTypes = {
	to: PropTypes.string.isRequired,
	children: PropTypes.string.isRequired,
}

export const PrimaryButton = (props) => <Button {...props} className={generateClassList(props.className, styles.btnPrimary)}>{props.children}</Button>

export const SecondaryButton = (props) => <Button  {...props} className={generateClassList(props.className, styles.btnSecondary)}>{props.children}</Button>

export const SubmitButton = (props) => <button type="submit" {...props} className={generateClassList(props.className, styles.btnPrimary)}>{props.children}</button>