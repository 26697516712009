import React from 'react';
import PropTypes from 'prop-types';
import Responsive from 'react-responsive';

export const Desktop = props => <Responsive {...props} minWidth={1200}/>;
export const Tablet = props => <Responsive {...props} minWidth={768} maxWidth={1199}/>;
export const Mobile = props => <Responsive {...props} maxWidth={767}/>;
export const TabletUp = props => <Responsive {...props} minWidth={768} />;
export const TabletDown = props => <Responsive {...props} maxWidth={1199} />;
export const HiddenUnder = props => <Responsive {...props} minWidth={props.minWidth} />;

HiddenUnder.propTypes = {
	minWidth: PropTypes.number.isRequired,
}
