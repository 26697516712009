import axios from 'axios';

const API_URL = 'https://blog.theamplifyteam.com';

/**
 * Concatenates CSS Module class names passed to it
 * Allowing you to define a "base" class, and then provide
 * whatever additional CSS you need to the component using it
 * @param  {...[array]} classes As many classes as you want, seperated by comma
 * @return {string}            A space seperated string of the CSS module generated class name
 */
export const generateClassList = (...classes) => (
	classes.filter(element => element).join(' ')
)


/**
 * Capitilized the first letter of a string
 * @param  {[type]} string [description]
 * @return {[type]}        [description]
 */
export const uppercaseFirst = (string) => {
	if(typeof string === 'undefined') return;
    return string.charAt(0).toUpperCase() + string.slice(1);
}

// shamelessly stolen from
// https://medium.com/@TCAS3/debounce-deep-dive-javascript-es6-e6f8d983b7a1
export const debounce = (fn, time) => {
  let timeout;

  return function() {
    const functionCall = () => fn.apply(this, arguments);

    clearTimeout(timeout);
    timeout = setTimeout(functionCall, time);
  }
}

export const extractNumber = (string) => {
	return string.match(/\d+/g).map(Number);
}

/**
 * Non-destructive chunk method for arrays
 */
export const chunk = (array, chunk) => {
  var i, j, temparray = [];
  for (i = 0, j = array.length; i < j; i += chunk) {
    temparray.push(array.slice(i, i + chunk));
    // do whatever
  }
  return temparray;
}



export const backendQuery = (route, callback) => {
	axios.get(`${API_URL}/wp-json/amplify/v1/${route}`)
	.then( function( response ) {
		callback(response.data);
	})
	.catch( function(error)  {
		throw(error)
	});

}


export const backendPost = (route, data, callback) => {
  axios.post(`${API_URL}/wp-json/amplify/v1/${route}`, data)
  .then( function( response ) {
    // console.log(response);
    callback(response.data);
  })
  .catch( function(error)  {
    throw(error)
  });
}