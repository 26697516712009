import React from 'react'
import {SocialMediaLinks, AmplifyAddress} from './../shared/Unique';
import footerLogo from './../../assets/footer-logo.png'
import {Container, ImageLink, Navigation} from './../../components/shared/PageContent';
import {Row, Col} from 'react-flexbox-grid';
import { blogUrl } from '../../navLinks';
import styles from './../../assets/scss/footer.module.scss';
import {PrimaryButton} from '../../components/shared/Buttons';
import { generateClassList } from '../helpers';

const dateObj = new Date();

export const ContactPageFooter = ({ className }) => (
    <div className={generateClassList(styles.contactFooter, className)}>
      <PrimaryButton to="/contact">Contact Us</PrimaryButton>
    </div>
)

export const Footer = () => (<footer className={styles.siteFooter}>
  <Container>
    <Row>
      <Col md={5} xl={6}>
        <Row className={styles.addressContainer}>
          <Col xs={12} xl={6}  className={styles.addressCol}><AmplifyAddress/></Col>
          <Col xs={12} xl={6}  className={styles.socialMedia}>
            <SocialMediaLinks />
          </Col>
        </Row>
      </Col>
      <Col xs={12} sm={6} md={4} xl={2} className={styles.navColumn}>
        <h4 className={styles.navTitles}>Services</h4>
        <Navigation className={styles.nav} links={{
            '/event-planning' : 'Event Planning',
            '/event-planning/corporate': 'Corporate Events',
            '/event-planning/turn-key': 'Turn Key Events',
            // '/event-planning/wedding' : 'Wedding Planning',
            '/branding' : 'Branding',
            '/social-media' : 'Social Media',
            '/advertising' : 'Advertising/PR',
            '/design-creation' : 'Design and Creation',
            '/customer-experience' : 'Customer Experience',
            '/marketing-consultant' : 'Marketing Consultant'
          }}/>
      </Col>
      <Col xs={12} sm={6} md={3} xl={2} className={styles.navColumn}>
        <h4 className={styles.navTitles}>About Amplify</h4>
        <Navigation className={styles.nav} links={{
            '/about' : 'About',
            [blogUrl] : 'Latest News',
            '/contact' : 'Contact Us'
          }}/>
      </Col>
      <Col className={styles.footerLogo} xs={12} xl >
        <ImageLink src={footerLogo} to="/"/>
      </Col>
    </Row>
  </Container>
  <div className={styles.credit}>
    <p>&copy; Amplify Marketing {dateObj.getFullYear() } - All Rights Reserved</p>
    <p>Web Development by <a href="http://bluefiremediagroup.com" target="_blank" rel="noopener noreferrer">Blue Fire Media</a></p>
  </div>
</footer>)
