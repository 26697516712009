import React from 'react';
import ReactAudioPlayer from 'react-audio-player';
import steamMachineSound from '../../assets/audio/machine-steam-homepage.mp3';
import thunda from '../../assets/audio/thunderstorm.mp3';
import lightning from '../../assets/audio/lightning-in-bottle.mp3';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { generateClassList } from '../helpers';
import styles from '../../assets/scss/audio-player.module.scss';
import {Desktop } from './MediaQueries';

const AUDIO_PAGES = {
  '/' : steamMachineSound,
  '/marketing-consultant': lightning,
  '/branding': thunda,
}
export class AudioPlayer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      src: props.src,
      audible: props.audible,
      hiddenAudible: props.hiddenAudible,
    }
  }

  componentDidMount() {
    const { location: { pathname } } = this.props;
     this.setTimer();
    this.setAudioFile(pathname);

  }

  componentWillUnmount() { clearTimeout(this._timer); }

  componentDidUpdate(prevProps, prevState) {
    const { location: { pathname } } = this.props;

    if(prevProps.location.pathname !== pathname) {


      this.setAudioFile(pathname);
    }


  }

  setTimer = () => {
    // clear any existing timer
      if(this._timer !== null) {
        clearTimeout(this._timer);
      }
      // console.log('set timer');
      // hide after `delay` milliseconds
      this._timer = setTimeout(function(){
        // console.log('toggling hiddenaudible');
        this.setState({hiddenAudible: true});
      }.bind(this), this.props.delay);
  }


  toggleAudible = () => {
    const { audible: current } = this.state;


    this.setState({
      audible: !current
    })
  }
  setAudioFile = (pathname) => {
    const file = AUDIO_PAGES[pathname];
    this.setState({
      src: file,
    })
  }
  render() {
    const displayClass = (this.state.src) ? styles.see : styles.hide;
    return (
        <Desktop>
        <span onClick={this.toggleAudible} className={generateClassList(styles.audioPlayerWrapper, displayClass)}>
          { (this.state.audible)
            ? ( <span>
                  <FontAwesomeIcon className={generateClassList(styles.volumeIcon, styles.active)} icon="volume-up" />
                  { (this.state.src && this.state.hiddenAudible)
                    ? <Desktop><ReactAudioPlayer
                        src={this.state.src}
                        autoPlay={this.state.hiddenAudible}
                        loop
                        volume={0.1}
                      /></Desktop>
                    : <span />
                  }

                </span>
            ) : (
              <span className={styles.volumeIconAlignment} >
                <FontAwesomeIcon className={generateClassList(styles.volumeIcon, styles.muted)} icon="volume-off" />
                <FontAwesomeIcon className={generateClassList(styles.volumeIconX, styles.muted)} icon="times" />
              </span>
            )

          }
      </span>
      </Desktop>
    )
  }
}

AudioPlayer.defaultProps = {
  src: '',
  audible: true,
  hiddenAudible: false,
  delay: 180000, // 3 minutes
}


export default withRouter(AudioPlayer);