import {Loader} from './components/Loader'


export const blogUrl = 'http://blog.theamplifyteam.com';
export const galleryUrl = 'http://blog.theamplifyteam.com/gallery';
export const newsletterUrl = 'http://blog.theamplifyteam.com/category/newsletters/';

export const NavLinks = [
  {
    path: '/',
    title: 'Home',
    exact: true,
    component: Loader({
      loader: () => import ("./pages/Home")
    })
  }, {
    path: '/about',
    title: 'About Us',
    component: Loader({
      loader: () => import ("./pages/About")
    })
  }, {
    path: '/event-planning',
    title: 'Event Planning',
    exact: true,
    component: Loader({
      loader: () => import ("./pages/EventPlanning")
    }),
    children: [
      {
        path: '/event-planning/corporate',
        title: 'Corporate',
        exact: true,
        component: Loader({
          loader: () => import ("./pages/Services/Corporate")
        })
      }, {
        path: '/event-planning/special-occasion',
        title: 'Special Occasion',
        exact: true,
        component: Loader({
          loader: () => import ("./pages/Services/SpecialOccasion")
        })
      }, {
        path: '/event-planning/turn-key',
        title: 'Turn Key Events',
        exact: true,
        component: Loader({
          loader: () => import ("./pages/Services/TurnKey")
        })
      // }, {
      //   path: '/event-planning/wedding',
      //   title: 'Wedding Planning',
      //   exact: true,
      //   component: Loader({
      //     loader: () => import ("./pages/Services/Wedding")
      //   })
      }
    ]
  }, {
    path: '/branding',
    title: 'Branding',
    component: Loader({
      loader: () => import ("./pages/Branding")
    })
  }, {
    path: '/social-media',
    title: 'Social Media',
    component: Loader({
      loader: () => import ("./pages/SocialMedia")
    })
  }, {
    path: '/advertising',
    title: 'Advertising',
    component: Loader({
      loader: () => import ("./pages/Advertising")
    })
  }, {
    path: '/design-creation',
    title: 'Design and Creation',
    component: Loader({
      loader: () => import ("./pages/Design")
    })
  }, {
    path: '/customer-experience',
    title: 'Customer Experience',
    component: Loader({
      loader: () => import ("./pages/CustomerExperience")
    })
  }, {
    path: '/marketing-consultant',
    title: 'Marketing Consultant',
    component: Loader({
      loader: () => import ("./pages/MarketingConsultant")
    })
  }, {
    path: '/everything-else',
    title: 'Everything Else',
    component: Loader({
      loader: () => import ("./pages/EverythingElse")
    })
  }, {
    path: '/contact',
    title: 'Contact',
    component: Loader({
      loader: () => import ("./pages/Contact")
    })
  }
]
