import React from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Header from './components/layout/Header';
import {Footer} from './components/layout/Footer';
import {NavLinks} from './navLinks.js'
import {omit} from 'lodash';
import FourOhFour from './pages/FourOhFour'
import { library } from '@fortawesome/fontawesome-svg-core'
// include icons by their filenames from here https://github.com/FortAwesome/Font-Awesome/tree/master/advanced-options/use-with-node-js
import { faChevronLeft, faChevronRight, faFrown, faVolumeUp, faTimes, faVolumeOff} from '@fortawesome/free-solid-svg-icons'
import { faFacebookF, faTwitter, faYoutube, faLinkedinIn, faPinterest, faInstagram} from '@fortawesome/free-brands-svg-icons';
import Modal from './components/shared/Modal';

library.add(
  faChevronLeft,
  faChevronRight,
  faFacebookF,
  faTwitter,
  faYoutube,
  faLinkedinIn,
  faPinterest,
  faInstagram,
  faFrown,
  faVolumeUp,
  faTimes,
  faVolumeOff,
);



// // Firefox 1.0+
// let isFirefox = typeof InstallTrigger !== 'undefined';

// Internet Explorer 6-11
let isIE = /*@cc_on!@*/false || !!document.documentMode;

// // Edge 20+
// let isEdge = !isIE && !!window.StyleMedia;

// // Chrome 1+
// let isChrome = !!window.chrome && !!window.chrome.webstore;


export class BrowserHelperModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: props.show
    }
  }

  hideModal = () => {
    this.setState({
      show: false
    })
  }
  render() {
    return (
      <Modal show={this.state.show} handleClose={this.hideModal} childClass="badBrowserModal">
        <div className="container">
        <h1>It appears you are using internet explorer.</h1>
        <p>This website runs the best in either: <a href="https://www.google.com/chrome/" rel="noopener noreferrer" target="_blank">Chrome</a>, <a href="https://www.mozilla.org/en-US/firefox/new/" rel="noopener noreferrer" target="_blank">Firefox</a> or <a href="https://www.microsoft.com/en-us/windows/microsoft-edge" rel="noopener noreferrer" target="_blank">Microsoft Edge</a></p>
        </div>
      </Modal>
    )
  }
}

BrowserHelperModal.defaultProps = {
  show: true,
}

export const App = (props) => {
  var i = 0;
  const routes = [];

  NavLinks.forEach(item => {
    const itemProps = omit(item, ['children'])
    routes.push(<Route key={i++} {...itemProps}/>)
    // pack on the routes for child pages.
    // this will only look one level deep...any further and this function should become recursive
    if (item.children) {
      item.children.forEach(child => {
        routes.push(<Route key={i++} {...child}/>)
      })
    }
  })

  return (<BrowserRouter onUpdate={() => window.scrollTo(0, 0)}>
    <div style={{overflow: 'hidden'}}>
      <Header/>
        <Switch>
          {routes}
           <Route render={() => <FourOhFour />} />
        </Switch>
      <Footer/>
      { (isIE)
    ? <BrowserHelperModal />
    : <span className="thanksForNotUsingInternetExplorer" />
  }
    </div>

  </BrowserRouter>)
};
