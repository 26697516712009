import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const AmplifyAddress = (props) => (<address>
  7130 South Westnedge
  <br/>
  Portage, MI 49002
  <br/>
  <span className="ph">
    <a href="tel:2692519067">(269) 251-9067</a>
  </span>
</address>)
export const SocialMediaLinks = (props) => (<ul className={props.className}>
  <li>
    <a href="https://www.facebook.com/TheAmplifyTeam/" target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon icon={['fab', 'facebook-f']} />
    </a>
  </li>
  <li>
    <a href="https://twitter.com/JustAMPlifyllc" target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon icon={['fab', 'twitter']} />
    </a>
  </li>
  <li>
    <a href="https://www.youtube.com/channel/UCFbSDy6hOOMmjvezOmtX1pA" target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon icon={['fab', 'youtube']} />
    </a>
  </li>
  <li>
    <a href="https://www.linkedin.com/company/amplifymarketingandeventplanning/" target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon icon={['fab', 'linkedin-in']} />
    </a>
  </li>
  <li>
    <a href="https://www.pinterest.com/amplifymarketingconsultants/ " target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon icon={['fab', 'pinterest']} />
    </a>
  </li>
  <li>
    <a href="https://www.instagram.com/amplifymarketingllc/" target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon icon={['fab', 'instagram']} />
    </a>
  </li>
</ul>)