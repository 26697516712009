import React from 'react';
import Loadable from "react-loadable";
import styles from '../assets/scss/loader.module.scss';
import LoadingIcon from './shared/LoadingIcon';

/**
 * Wrapper around Loadable that provides
 * Loading Text, a delay, and timeout handler
 * @param {object} opts Additional arguments for Loadable
 */
export function Loader(opts) {
  return Loadable(Object.assign({
    loading: LoadingText,
    delay: 200,
    timeout: 10000
  }, opts));
}

/**
 * Displays before Loadable promise is resolved
 */
function LoadingText(props) {

  if (props.error) {
    return <div>Error!
      <button className="btn btn-default" onClick={props.retry}>Retry</button>
    </div>;
  } else if (props.pastDelay) {
    return <div>Loading...</div>;
  } else {
    return null;
  }
}

const checkImage = path =>
new Promise(resolve => {
    const img = new Image();
    img.onload = () => resolve({path, status: 'ok'});
    img.onerror = () => resolve({path, status: 'error'});

    img.src = path;
});

export class LoadingAwait extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      show: false
    }
  }

  componentDidMount() {

    const el = this;
    Promise.all(this.props.paths.map(checkImage)).then(() => {
      el.setState({
        show: true
      })
    });
    // document.addEventListener('DOMContentLoaded', this.renderChildren, false);
  }

  componentWillUnmount() {
    // document.removeEventListener('DOMContentLoaded', this.renderChildren, false);
  }


  render() {
    var hiddenStyle = {height: 0, overflow: 'hidden'};
    var visibleStyle = {};
    return (
      <div>
         <div style={this.state.show ?  visibleStyle : hiddenStyle}>
          { this.props.children }
         </div>

        <div style={this.state.show ?  hiddenStyle : visibleStyle}>
          <div className={styles.loadingPage}>
            <div className={styles.loadingPageContent}>
              <LoadingIcon />
              <h1 className={styles.loadingText}>Loading...</h1>
            </div>
          </div>
        </div>
      </div>
    )
  }
}