import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {generateClassList} from './../helpers';
import styles from './../../assets/scss/page-content.module.scss';


/**
 * The main content container wrapper for the application
 * Used on most pages to center the content and apply some basic styling
 * with a max width, centered content, etc.
 */
export const Container = ({ className, children}) => (
  <div className={generateClassList(className, styles.containerComponent)}>
    {children}
  </div>);

Container.defaultProps = {
  className: null,
}

Container.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]).isRequired,
};


/**
 * Returns a list of react router anchor tags going to a relative url
 */
export const Navigation = ({ links, className }) => {
  const listItems = Object.keys(links).map(link => (
    <li key={link}>
      { (link.substring(0,4) === 'http')
        ? <a href={link}>{links[link]}</a>
        : <Link to={link}>{links[link]}</Link>
      }
    </li>
    )
  )

  return <ul className={className}>{listItems}</ul>
};

Navigation.defaultProps = {
  links: {},
};

Navigation.propTypes = {
  links: PropTypes.object,
};


/**
 * Returns an image that links to a specific react-router page
 */
export const ImageLink = ({
  className, to, src, alt,
}) => (<div className={className}>
  <Link to={to}><img className="img-responsive" src={src} alt={alt}/></Link>
</div>)

ImageLink.defaultProps = {
  style: {},
  alt: '',
};

ImageLink.propTypes = {
  style: PropTypes.object,
  to: PropTypes.string.isRequired,
  alt: PropTypes.string,
  src: PropTypes.string.isRequired
};


/**
 * Fairly self explanatory...but renders the children with an image background behind them.
 * Useful in z-index nightmares such as the homepage
 */
export const ImageBackground = ({
  wrapperClass, src, imageClass, alt, children,
}) => (
  <div className={generateClassList(styles.imageBgWrapper, wrapperClass)}>
  <img src={src} className={generateClassList(styles.image, imageClass)} alt={alt} />
    {children}
  </div>
);

ImageBackground.defaultProps = {
  wrapperClass:  '',
  style: {},
  alt: '',
};

ImageBackground.propTypes = {
  wrapperClass: PropTypes.string,
  style: PropTypes.object,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string
};


/**
 * Returns a fixed image background using the "cover" attribute.
 * This is a very popular technique made popular by CSS tricks
 * https://css-tricks.com/perfect-full-page-background-image/
 */
export const FixedImageBackground = ({ position, src, attachment, wrapperClass, children }) => {
  const inlineStyles = {
    background: `url(${src})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: `center ${position}`,
    backgroundAttachment: attachment
  };

  return (
    <div style={inlineStyles} className={generateClassList(styles.fixedImageBgWrapper, wrapperClass)}>
      {children}
    </div>
    )
}

FixedImageBackground.defaultProps = {
  position: 'center',
  attachment: 'scroll',
};

FixedImageBackground.propTypes = {
  src: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]).isRequired,
  position: PropTypes.oneOf([
    'left','right','center','bottom', 'top',
  ]),
  attachment: PropTypes.oneOf([
    'scroll','fixed','inherit'
  ]),
  wrapperClass: PropTypes.string,
};


/**
 * Returns the children with a looping video playing in the background
 */
export const VideoBackground = ({
  absContent, className, files, childWrapperClass, children, poster, videoClass,
}) => {
  const vidClass = (absContent) ? styles.absoluteVideo : null;
  const contentStyles = (absContent) ? styles.absoluteContentWrapper : styles.staticContentWrapper;

  return (<div className={generateClassList(styles.videoBackground, className)}>
    <VideoPlayer className={generateClassList(vidClass, videoClass)} files={files} poster={poster} />
    <div className={generateClassList(contentStyles, childWrapperClass)}>
      {children}
    </div>
  </div>)
}

VideoBackground.defaultProps = {
  absContent: false,
  childWrapperClass: null,
  className: null,
};

VideoBackground.propTypes = {
  files: PropTypes.object.isRequired,
  style: PropTypes.object,
  absContent: PropTypes.bool,
  childWrapperClass: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]).isRequired,

}

/**
 * The main video player
 */
export const VideoPlayer = ({ className, files, poster }) => {

  const sources = Object.keys(files).map(mime => (<source key={mime} type={mime} src={files[mime]}/>))

  return (
    <video className={className} poster={poster} autoPlay="autoplay" loop="loop" muted="muted">
      {sources}
    </video>
  )
}

VideoPlayer.defaultProps = {
  className: '',
}

VideoPlayer.propTypes = {
  files: PropTypes.object.isRequired,
  className: PropTypes.string,
  poster: PropTypes.string.isRequired,
}


/**
 * Returns an image with a line and title under it...basically only used on the homepage.
 */
export const ImageWithLineTitle = ({ to, className, src, alt, text }) => (
<span className={styles.lineTitle}>
  <Link to={to} className={generateClassList(styles.link, className)}>
    <img className="img-responsive" src={src} alt={alt}/>
    <span className={styles.title}>
      {text}
    </span>
  </Link>
</span>
)

ImageWithLineTitle.defaultProps = {
  className: '',
  alt: '',
}

ImageWithLineTitle.propTypes = {
  className: PropTypes.string,
  alt: PropTypes.string,
  to: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}




/**
 * Text block with a italicized prefix, large block typed title, and some content following
 */
export const TitleTextBlock = ({
  right, wrapperClass, prefixStyles, prefix, titleStyles, title, contentStyles, children
}) => {
  const rightAlignedClass = (right) ? styles.rightAligned : null;
  return (
    <div  className={generateClassList(styles.titleTextBlock, wrapperClass, rightAlignedClass)}>
      { (prefix)
        ? <small className={generateClassList(styles.prefix, prefixStyles)}>{prefix}</small>
        : <span />
      }
      <h3 className={generateClassList(styles.title, titleStyles)}>{title}</h3>
      <div className={generateClassList(contentStyles, styles.content)}>{children}</div>
    </div>
  )
}

TitleTextBlock.defaultProps = {
  right: false,
  prefix: '',
  prefixStyles: '',
  titleStyles: '',
  contentSyles: '',
}

TitleTextBlock.propTypes = {
    right: PropTypes.bool,
    prefix: PropTypes.string,
    prefixStyles: PropTypes.string,
    titleStyles: PropTypes.string,
    contentStyles: PropTypes.string
}
